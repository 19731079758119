
@import '../../../styles/variables';

.sgh-cms-product-tile {
  .details-wrapper {
    .tile-number {
      font-weight: bold;
      font-size: 5rem;
      line-height: 6.1rem;
      color: $alto-4;
      margin-top: 0.2rem;
    }
  }
  .picture {
    padding-top: calc(87 / 200) * 100%;
    &__image {
      width: 100%;
      object-fit: cover;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 38rem;

      &--hover {
        display: none;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  &--wall-2023,
  &--wall-2023-best-seller {
    width: 32rem;
    @screen mdr {
      width: 100%;
      max-width: 23.6rem;
    }
    .picture {
      padding-top: 0;
      background-color: $alabaster;
      height: 33.9rem;
      width: 32rem;
      @screen mdr {
        width: 100%;
        height: 25rem;
      }
      &__image {
        &--hover {
          height: auto;
          display: none;
          object-fit: cover;
          width: 100%;
          object-fit: cover;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          max-width: 38rem;
        }
      }
    }
    .brand {
      letter-spacing: 0.1rem;
    }
  }

  .price__original + .price__current {
    color: $sangria;
  }

  @screen lg {
    &--wall-2023:hover,
    &--wall:hover,
    &--box:hover {
      .picture__image {
        opacity: 0;
        display: block;
        &--hover {
          opacity: 1;
          display: block;
        }
      }
    }
  }
}
