
@import '../../../styles/_variables';
::v-deep .swiper-slide {
  width: 100%;
  @screen mdr {
    width: 30rem !important;
  }

  .text-box {
    max-width: 90%;
    padding-top: 1.6rem;
    @screen mdr {
      width: 100vw;
      max-width: initial;
      padding: 0 1.6rem;
      visibility: hidden !important;
      opacity: 0 !important;
    }
  }
  &.swiper-slide-active .text-box {
    position: relative;
    visibility: visible !important;
    opacity: 1 !important;
    transition: 0.2s linear;
  }
}
.sgh-service-carousel {
  padding: 0 4rem;
  color: $mine-shaft;
  @screen mdr {
    padding: 0;
  }
  &__title {
    font-size: 1.4rem;
    line-height: 1.3;
    margin-bottom: 2.4rem;
    color: $mine-shaft;
    @screen mdr {
      font-size: 1.6rem;
      padding-left: 1.6rem;
      line-height: 2.4rem;
      font-weight: normal;
      margin-bottom: 1.6rem;
    }
  }
  .sgh-service-carousel-grid {
    display: grid;
    gap: 0.8rem;
    &.sgh-service-carousel-grid {
      &_one {
        grid-template-columns: minmax(0, 1fr);
      }
      &_two {
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      }
      &_three {
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
      }
    }
  }
}

::v-deep .swiper-container-css-mode > .swiper-wrapper > .swiper-slide {
  scroll-snap-align: center center;
}
