
@import '../../../styles/variables';
@import '../../../styles/fonts';
.sgh-service-carousel-item {
  width: 100%;
  @screen mdr {
    width: 30rem;
  }
  &__product {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
  &__picture {
    aspect-ratio: 4/3;
    position: relative;
    picture {
      display: -ms-flexbox;
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        @screen mdr {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          max-width: 30rem;
          max-height: 22.5rem;
        }
      }
    }
  }
  &__img {
    width: 44.8rem;
    height: 36rem;
  }
  &__pretitle {
    font-size: 1.2rem;
    line-height: 1.8rem;
    text-transform: uppercase;
    color: $mine-shaft;
  }
  &__title {
    @include actaHeadlineFontTitle();
  }
  &__desc {
    font-size: 1.4rem;
    padding-top: 0.4rem;
    color: $mine-shaft;
    line-height: 1.5;
    @screen mdr {
      font-size: 1.6rem;
    }
    p {
      color: $mine-shaft;
      margin-bottom: 1.6rem;
    }
  }

  &__cta {
    display: flex;
    flex-flow: wrap;
    font-size: 1.4rem;
    gap: 2.4rem;
    text-decoration: underline;
    a {
      color: $mine-shaft;
    }
  }
}
