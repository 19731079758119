
@import '../../../styles/fonts.scss';
@import '../../../styles/variables';

.sgh-payment-paypal {
  &-content {
    width: auto;
    color: $mine-shaft;

    &__head {
      padding: 0;
    }

    &__title {
      padding: 6.7rem 3rem 2.4rem;
      @include actaHeadlineFont();
      font-size: 2.8rem;
      font-weight: 400;
      line-height: 2.9rem;
      text-align: center;
    }

    &__body {
      overflow-y: auto;
      line-height: normal;
      padding: 0 3.2rem;

      .description {
        font-size: 1.4rem;
        margin-top: 0.8rem;
        line-height: 2.4rem;
      }

      .iconed-list__item {
        padding-left: 0;
      }
    }
  }

  @screen mdr {
    right: 1.6rem;
    left: 1.6rem;
    max-height: 60rem;

    &-content {
      width: auto;

      &__head {
        padding: 0;
      }

      &__title {
        padding: 4.8rem 2rem 3.2rem;
        font-size: 2.4rem;
        line-height: 2.88rem;
      }

      &__body {
        padding: 0 2rem 1.5rem;
        height: 55vh;
      }
    }
  }
}
