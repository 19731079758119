
@import '../../../styles/fonts';
@import '../../../styles/variables';

.sgh-service-module {
  min-height: 26rem;
  content-visibility: auto;
  contain-intrinsic-size: auto none;
    @screen mdr {
    padding-left: 2.4rem;
  }
  &__container {
    padding: 3.2rem 3.2rem 2.4rem 3.2rem;
    @screen mdr {
      padding: 3.2rem 2.4rem 2.4rem 0;
    }
  }
  &__title {
    @include actaHeadlineFontTitle();
    line-height: 2.8rem;
    margin-bottom: 0.8rem;
  }
  &__subtitle {
    margin-bottom: 0.8rem;
    font-size: 1.4rem;
    line-height: 2.1rem;
  }
  &__cta {
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 500;
    text-decoration: underline;
  }

  &.align-left {
    text-align: left;
  }
  &.align-center {
    text-align: center;
  }

  &--carousel-items {
    ::v-deep .swiper-button {
      display: none;
      &-disabled {
        display: none !important;
      }
      &-prev {
        left: 0;
        transform: rotate(180deg);
      }
      &-prev,
      &-next {
        width: 4rem;
        height: 4rem;
        background-color: $white;
        opacity: 0.8;
        top: 50%;
        @screen lgr {
          display: flex;
          background-color: $white;
          opacity: 1;
        }
        &:hover {
          background-color: $white;
          opacity: 1;
        }
        &:after {
          content: '';
        }
        @screen mdr {
          display: none;
        }
      }
      @screen lgr {
        display: flex;
      }
    }
  }

  ::v-deep .swiper-slide {
    @screen mdr {
      width: 24rem;
    }
  }
  ::v-deep .swiper-pagination {
    display: flex;
    width: auto;
    padding: 2.4rem 2.4rem 3.2rem 0;
    bottom: 0;
    @screen md {
      display: none;
    }
  }
  ::v-deep .swiper-pagination-bullets {
    .swiper-pagination-bullet {
      margin-top: 3.2rem;
      &:first-child {
        border-radius: 0.2rem 0 0 0.2rem;
      }
      &:last-child {
        border-radius: 0 0.2rem 0.2rem 0;
      }
      width: 100%;
      margin: 0;
      height: 0.2rem;
      background-color:rgba(0, 0, 0, 0.2) !important;
      border-radius: 0;
      border: none;
      &-active {
        background-color: $black !important;
        opacity: 1;
        border-radius: 0.2rem;
      }
    }
  }

  &.cms-custom-color-black {
    ::v-deep .swiper-pagination-bullets {
      .swiper-pagination-bullet {
        background-color: $boulder !important;
        &-active {
          background-color: $white !important;
        }
      }
    }
  }
}
