
@import '../../../styles/variables';
@import '../../../styles/fonts.scss';
.title-big {
  @include actaHeadlineFontTitle();

  font-size: 2.8rem;
  font-weight: 400;
  line-height: 2.8rem;
  margin-right: 1.8rem;
}

::v-deep .swiper-wrapper {
  .skeleton {
    margin-right: 0.8rem;
    &-img {
      display: flex;
      height: 33.9rem;
      width: 32rem;
      background-color: $white-smoke;
      @screen mdr {
        width: 23rem;
        height: 25rem;
      }
    }
    &-text {
      display: flex;
      width: 50%;
      height: 1.6rem;
      background-color: $white-smoke;
      margin: 1.6rem 0 0.8rem;
      &:last-child {
        width: 40%;
        margin: 0;
      }
    }
  }
}

.swiper-tiles-2023 {
  margin-right: 0rem;
  margin-left: 0rem;
  transition: margin-left linear 0.2s;
  @screen mdr {
    ::v-deep .swiper-container {
      padding-left: 1.8rem;
      padding-right: 1.8rem;

      .swiper-pagination-bullet {
        &:first-child {
          border-radius: 0.5rem 0 0 0.5rem;
        }
        &:last-child {
          border-radius: 0 0.5rem 0.5rem 0;
        }
        width: 100%;
        margin: 0;
        margin-top: 3.6rem;
        height: 0.5rem;
        background-color: $mine-shaft;
        opacity: 0.1;
        border-radius: 0;
        border: none;
        &-active {
          background-color: $mine-shaft;
          opacity: 1;
          border-radius: 0.5rem;
        }
      }
    }
  }

  &.isSwiperFirst {
    @screen mdr {
      margin-left: 0;
    }
    margin-left: 4rem;
    transition: all linear 0.3s;
  }

  &:hover {
    ::v-deep .swiper-button {
      &-prev,
      &-next {
        display: flex;
        @screen mdr {
          display: none;
        }
      }
      &-prev {
        transform: rotate(180deg);
        left: 0;
      }
      &-next {
        right: 0;
      }
    }
  }
  &--title {
    margin: 4rem 0 2rem 4rem;

    @screen mdr {
      margin-left: 1.6rem;
      margin-bottom: 1.6rem;
    }
  }
  ::v-deep .swiper-slide {
    width: 32rem !important;
    @screen mdr {
      width: 23.6rem !important;
    }
  }

  ::v-deep .swiper-button {
    display: none;
    &-disabled {
      display: none !important;
    }
    &-prev,
    &-next {
      display: none;
      width: 4.5rem;
      height: 4.5rem;
      background-color: $white;
      opacity: 0.8;
      top: calc(33.9rem / 2);
      @screen mdr {
        top: calc(25rem / 2);
      }
      &:hover {
        background-color: $white;
        opacity: 1;
      }
      &:after {
        content: '';
      }
    }
  }
  ::v-deep .swiper-pagination {
    display: flex;
    width: 27.4rem;
    margin: auto;
    @screen md {
      display: none;
    }
  }
  ::v-deep .swiper-container-css-mode > .swiper-wrapper > .swiper-slide {
    scroll-snap-align: center center;
  }
}
