
@import '../../../styles/variables';
.insurance-holder {
  background: $alabaster;
  display: flex;
  align-items: center;
  padding: 1.2rem;
  padding-left: 4.2rem;
  position: relative;
  margin: -2.4rem 0 0;
  width: 100%;
  @screen lgr {
    margin-top: -3.6rem;
  }

  .tcl-checkbox {
    position: absolute;
    top: 1.2rem;
    left: 1.2rem;
    z-index: 5;
  }
  p {
    color: $black;
  }

  a {
    font-weight: 500;
    text-decoration: underline;
  }
}

.prevent-layer {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: $white;
  opacity: 0.5;
}

body {
  &.prevent-active {
    .prevent-layer {
      display: block;
      z-index: 10;
    }
  }
}
.sgh-minicart-container-inner__content .insurance-holder {
  margin: 0.4rem 0 2.4rem;
  @screen lgr {
    margin: 0 0 1.2rem;
  }
}

.quebec-link {
  white-space: nowrap;
}
