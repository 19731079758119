
@import '../../../styles/fonts.scss';
@import '../../../styles/variables';

.sgh-payment-klarna-content {
  &_top {
    h4 {
      font-family: Helvetica Neue, sans-serif;
      font-weight: 300;
      font-size: 2.4rem;
      margin: 4rem 0 1.3rem 0;
    }
    .info {
      color: $black;
      font-size: 1.4rem;
      line-height: 1.3;
      margin-bottom: 2.5rem;
      @screen mdr {
        font-size: 1.6rem;
      }
    }
  }
  &_middle {
    margin-bottom: 2.5rem;
    font-size: 1.4rem;
    line-height: normal;
    .info {
      font-size: 1.4rem;
      @screen mdr {
        font-size: 1.6rem;
      }
    }
    ul {
      list-style: none;
      @screen mdr {
        font-size: 1.6rem;
      }
    }
  }
  &_bottom {
    line-height: normal;
    .info {
      font-size: 1.2rem;
      @screen mdr {
        font-size: 1.4rem;
      }
    }
  }
}
