
@import '../../../styles/variables';

.sgh-payment-info {
  &__button {
    font-size: 1.4rem;
    margin-left: auto;
    color: $fade-gray;
    text-decoration: underline;
    border-style: none;
    background: transparent;
    padding: 0;
    &:hover {
      text-decoration: none;
    }
  }

  &__label {
    font-size: 1.4rem;
  }
}
