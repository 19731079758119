
@import '../../../styles/variables';

.frame-container {
  margin-left: 4rem;
  margin-right: 4rem;
  gap: 2.4rem;
  content-visibility: auto;
  contain-intrinsic-size: auto none;

  .Shop-by-brand {
    width: 33.5rem;
    height: 1.8rem;
    font-size: 1.4rem;
    flex-grow: 0;
    font-weight: normal;
    line-height: 1.3;
    color: $black;

    @screen mdr {
      width: 11.5rem;
      height: 2.1rem;
      font-size: 1.6rem;
      white-space: nowrap;
      font-weight: normal;
    }
  }

  .container-image-logo {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    column-gap: 0.8rem;
    row-gap: 0.8rem;
    width: 100%;

    .container-image {
      display: none;
      height: 6.1rem;
      align-items: center;
      text-align: center;
      background-color: $alabaster;

      &:last-child {
        background-color: $mercury-2;
      }

      &:hover {
        background-color: $ligth-gray;
      }

      img {
        height: 6.11rem;
        object-fit: contain;
        opacity: 0.8;
        max-width: 11.5rem;
      }
    }

    @screen lgr {
      display: grid;
      grid-template-columns: auto auto;

      .container-image:nth-child(-n + 7),
      .container-image:last-child {
        display: block;
      }
    }

    @screen md {
      .container-image:nth-child(-n + 17),
      .container-image:last-child {
        display: block;
      }
    }
  }

  .view-all-span {
    width: 6.6rem;
    height: 1.4rem;
    font-size: 1.4rem;
    line-height: 4;
    font-weight: 500;
    color: $mine-shaft;
    letter-spacing: 0.02rem;
    text-transform: uppercase;
  }

  @screen mdr {
    gap: 1.5rem;
    margin: 0 1.5rem;
  }
}
