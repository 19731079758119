
@import '../../../styles/variables';
@import '../../../styles/fonts.scss';

.hero-banner {
  &--video-controller {
    bottom: 2.4rem;
    left: 15rem;
    gap: 1.6rem;
    transition: linear 0.2;

    .player-controller {
      width: 4rem;
      height: 4rem;
      padding: 0.8rem;
      border-radius: 100%;
      display: inline-block;
      background: rgba(0, 34, 34, 0.4);
      span {
        width: 2.4rem;
        height: 2.4rem;
      }
    }

    @media screen and (max-width: 1800px) {
      left: 4rem;
    }

    @screen mdr {
      bottom: 4rem;
      left: 4rem;
    }
  }

  &--brand-name {
    bottom: 2.4rem;
    right: 15rem;
    font-size: 1rem;
    margin: 0;

    @media screen and (max-width: 1800px) {
      right: 4rem;
    }
  }

  ::v-deep .cms-cta-secondary-emperor-grey {
    &:hover {
      background-color: $emperor-trans;
      color: $emperor;
    }

    &:active {
      background-color: $emperor-trans-2;
      color: $emperor;
    }
  }

  ::v-deep .cms-cta-tertiary-emperor-grey {
    &:hover {
      text-decoration: none;
    }

    &:active {
      opacity: 0.6;
      box-shadow: none;
    }
  }

  .double-link {
    width: 50%;

    &:nth-child(2) {
      right: 0;
    }
  }

  .single-link {
    width: 100%;
  }

  &:active {
    .bg-images {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $black;
        opacity: 7%;
      }
    }
  }

  .bg-images {
    position: absolute;
    top: 0;
    animation: fadeIn 1s;
    height: 100%;
    width: 100%;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    video {
      width: 100%;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &__s {
    @screen md {
      aspect-ratio: 9/2;
    }

    .hero-banner--text-module {
      padding: 7rem 4rem;

      @screen mdr {
        padding: 1.6rem;
      }
    }

    .hero-banner__buttons {
      margin-bottom: 0.8rem;
      margin-top: 1rem;

      button,
      a {
      padding: 1.2rem;
      line-height: 1.6;
        &.cms-cta-tertiary-emperor-grey {
          justify-content: left;
          padding: 0;
          width: auto;
        }
      }
    }

    @screen mdr {
      height: 24rem;

      .background-link,
      .double-link,
      .single-link,
      .images-links {
        height: 24rem;
      }

      &:has(.text-outside) {
        height: auto;

        .bg-images {
          position: inherit;
          height: 24rem;
        }

        .hero-banner--video-controller {
          left: 2.4rem;
          top: 24rem;
          bottom: auto;
          transform: translateY(-100%);
          padding-bottom: 1.6rem;
        }

        .hero-banner--brand-name {
          top: 24rem;
          left: auto;
          right: 2.4rem;
          bottom: auto;
          transform: translateY(-100%);
          padding-bottom: 1.6rem;
        }
      }

      &.text-outside {
        width: 100%;
      }
    }
  }

  &__m {
    @screen md {
      aspect-ratio: 72/23;
    }

    @screen mdr {
      height: 46rem;

      .background-link,
      .images-links {
        height: 46rem;
      }

      &:has(.text-outside) {
        height: auto;

        .bg-images {
          position: inherit;
          height: 32rem;
        }

        .double-link,
        .single-link {
          height: 32rem;
        }

        .hero-banner--video-controller {
          left: 2.4rem;
          top: 32rem;
          bottom: auto;
          transform: translateY(-100%);
          padding-bottom: 1.6rem;
        }

        .hero-banner--brand-name {
          top: 32rem;
          left: auto;
          right: 2.4rem;
          bottom: auto;
          transform: translateY(-100%);
          padding-bottom: 1.6rem;
        }
      }

      &.text-outside {
        width: 100%;
      }

      .hero-banner--text-module {
        &.text-inside {
          &.top-left,
          &.top-center,
          &.top-right {
            padding-top: 9rem;
          }

          &.bottom-left,
          &.bottom-center,
          &.bottom-right {
            padding-bottom: 5.1rem;
          }
        }
      }
    }
  }

  &__l {
    @screen md {
      aspect-ratio: 144/59;
    }

    @screen mdr {
      height: 56rem;

      .background-link,
      .images-links {
        height: 56rem;
      }

      &:has(.text-outside) {
        height: auto;

        .bg-images {
          position: inherit;
          height: 40rem;
        }

        .double-link,
        .single-link {
          height: 40rem;
        }

        .hero-banner--video-controller {
          left: 1.6rem;
          top: 40rem;
          bottom: auto;
          transform: translateY(-100%);
          padding-bottom: 1.6rem;
        }

        .hero-banner--brand-name {
          top: 40rem;
          left: auto;
          right: 2.4rem;
          bottom: auto;
          transform: translateY(-100%);
          padding-bottom: 1.6rem;
        }
      }

      &.text-outside {
        width: 100%;
      }

      .hero-banner--text-module {
        &.text-inside {
          &.top-left,
          &.top-center,
          &.top-right {
            padding-top: 9rem;
          }

          &.bottom-left,
          &.bottom-center,
          &.bottom-right {
            padding-bottom: 5.1rem;
          }
        }
      }

      .hero-banner--video-controller {
        left: 2.4rem;
        top: 56rem;
        bottom: auto;
        transform: translateY(-100%);
        padding-bottom: 1.6rem;
      }

      .hero-banner--brand-name {
        top: 56rem;
        left: auto;
        right: 2.4rem;
        bottom: auto;
        transform: translateY(-100%);
        padding-bottom: 1.6rem;
      }
    }
  }

  &--text-module {
    width: fit-content;
    padding: 9rem 15rem;
    position: absolute;
    pointer-events: none;

    &.top-left {
      top: 0;
      left: 0;

      .hero-banner__buttons {
        justify-content: flex-start;
      }
    }

    &.top-center {
      width: 100%;
      top: 0;

      .hero-banner--text-module__wrapper {
        text-align: center;
        margin: 0 auto;

        p {
          text-align: center;
          margin: 0 auto;
        }
      }
    }

    &.top-right {
      top: 0;
      right: 0;

      .hero-banner__buttons {
        justify-content: flex-end;
      }

      .hero-banner--text-module__wrapper {
        text-align: right;
        margin-left: auto;
      }
    }

    &.center-left {
      top: 0;
      height: 100%;
      width: 100%;
      align-items: center;
      display: flex;

      .hero-banner__buttons {
        justify-content: flex-start;
      }
    }

    &.center-right {
      top: 0;
      right: 0;
      height: 100%;
      align-items: center;
      display: flex;

      .hero-banner__buttons {
        justify-content: flex-end;
      }

      .hero-banner--text-module__wrapper {
        text-align: right;
        margin-left: auto;
      }
    }

    &.center-center {
      top: 0;
      width: 100%;
      height: 100%;
      align-items: center;
      display: flex;

      .hero-banner--text-module__wrapper {
        p {
          text-align: center;
          margin: auto;
        }

        text-align: center;
        margin: auto;
      }
    }

    &.bottom-left {
      top: 0;
      height: 100%;
      align-items: flex-end;
      display: flex;

      .hero-banner__buttons {
        justify-content: left;
      }
    }

    &.bottom-right {
      top: 0;
      right: 0;
      height: 100%;
      align-items: flex-end;
      display: flex;

      .hero-banner__buttons {
        justify-content: flex-end;
      }

      .hero-banner--text-module__wrapper {
        text-align: right;
        margin-left: auto;
      }
    }

    &.bottom-center {
      top: 0;
      height: 100%;
      width: 100%;
      align-items: flex-end;
      display: flex;

      .hero-banner--text-module__wrapper {
        p {
          text-align: center;
          margin: 0 auto;
        }

        text-align: center;
        margin: 0 auto;
      }
    }

    &__wrapper {
      pointer-events: none;

      * {
        pointer-events: all;
      }
    }

    @media screen and (max-width: 1800px) {
      padding: 9rem 4rem;
    }

    @screen mdr {
      width: 100%;

      &.text-outside {
        position: static;
      }

      padding: 1.6rem 2rem 2.4rem;
    }

    &__wrapper {
      line-break: auto;
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
      width: 100%;

      p {
        max-width: 73rem;
        margin-bottom: 0;

        @screen mdr {
          margin: 0;
        }
      }

      &.is-more-three {
        .hero-banner__buttons {
          @screen lgr {
            justify-content: left;
            column-gap: 2%;

            button,
            a {
              &:not(.tertiary-2023) {
                flex-basis: 32%;
              }
            }
          }
        }
      }
      .hero-banner__buttons {
        @screen lgr {
          button,
          a {
            &:not(.tertiary-2023) {
              flex-basis: 46.5%;
            }
          }
        }
      }
    }
  }

  &__pre-header {
    font-size: 1.2rem;
    line-height: 1.8rem;
    letter-spacing: 0.1rem;
  }

  &__title {
    @include actaHeadlineFontTitle();
    font-size: 4rem;
    line-height: 4rem;
    letter-spacing: 0.02rem;
    line-break: auto;

    @screen mdr {
      font-size: 3.2rem;
      line-height: 3.8rem;
      letter-spacing: 0.016rem;
    }
  }

  &__caption {
    font-size: 1.2rem;
    letter-spacing: 0.05rem;

    a {
      text-decoration: underline;
    }
  }

  &__countdown {
    font-size: 1.8rem;
    line-height: 2.7rem;
    letter-spacing: 0.1rem;

    &.highlighted {
      color: $sangria;
    }
  }

  &__body-copy {
    font-size: 1.4rem;
    line-height: 2.1rem;

    @screen mdr {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }

  &__buttons {
    margin-bottom: 0.4rem;
    margin-top: 1.3rem;
    gap: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    button,
    a {
      padding: 0 1.2rem;
      max-width: 32.8rem;
      line-height: 1.14 !important;
      font-size: 1.4rem;

      &.tertiary-2023 {
        padding: 0;
        max-width: fit-content;
      }
    }

    @screen lgr {
      display: flex;
      justify-content: center;
      flex-grow: 1;
      flex-wrap: wrap;
      column-gap: 7%;

      button,
      a {
        &:not(.tertiary-2023) {
          width: auto !important;
          min-width: 10rem !important;
          white-space: inherit;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 1.2rem;
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
