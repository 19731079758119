
@import '../../../styles/variables';
.service-item {
  height: auto;
  &:last-child {
    margin-right: 0;
  }
  @screen lg {
    width: calc(100% / var(--banner-number));
    margin-right: 0.8rem;
  }

  &:last-child {
    .service-item__wrap {
      @screen mdr {
        margin-bottom: 0;
      }
    }
  }
  &__container {
    display: block;
    padding: 1.6rem 2.4rem;
    height: 100%;
  }
  &__icon {
    span,
    img {
      width: 6rem;
      height: 6rem;
    }
    @screen mdr {
      margin-top: 0;
    }
  }

  &__title {
    margin-top: 1rem;
    font-size: 1.2rem;
    text-transform: uppercase;
    margin-bottom: 0.4rem;
    letter-spacing: 0.03rem;
    line-height: 1.9rem;
    @screen lgr {
      margin-top: 0;
    }
  }

  &__text {
    font-size: 1.4rem;
    line-height: 2.2rem;

    p {
      margin: 0;
    }
    @screen lgr {
      font-size: 1.6rem;
    }
    .rte--list {
      list-style-position: inside;
      li {
        text-indent: -2rem;
        padding-left: 2rem;
      }
    }
  }
  &__footnote {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.8rem;
  }
  &__cta {
    margin-top: 0.8rem;
    button {
      font-weight: 500;
      font-size: 1.4rem;
      letter-spacing: 0.02rem;
    }
  }
}
